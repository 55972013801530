import { ReactNode, useContext, useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { ReportStatus } from "@parallel/vertex/enums/report.enums";
import { HydratedAssessmentReport } from "@parallel/vertex/types/report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { allReportReviewers } from "@parallel/vertex/util/report.util";
import { AssessmentReportList, AssessmentListType } from "@/components/assessment/report/AssessmentReportList";
import CreateReportModal from "@/components/assessment/report/CreateReportModal";
import { LoadingModal } from "@/components/common/content/LoadingModal";
import NotPermissable from "@/components/common/content/NotPermissable";
import { Button } from "@/components/common/elements/button/Button";
import SearchTextInput from "@/components/common/elements/input/SearchTextInput";
import CommonScreenLayout from "@/components/common/windows/OldCommonScreenLayout";
import config from "@/config";
import { AlertStoreContext, ApiStoreContext, UserStoreContext, loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("AssessmentReportHome", loggerContext);

const AssessmentReportHome = () => {
  const { reportApi } = useContext(ApiStoreContext);
  const alertStore = useContext(AlertStoreContext);
  const { currentUser, disableOldReportCreate } = useContext(UserStoreContext);
  const { userId } = currentUser || {};

  const [searchString, setSearchString] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const redirect = useLoggedRedirect(logger);

  const [{ result: allReports }, { execute: fetchAllReports }] = useAsync(() =>
    userId
      ? logger
          .wrapOperation("fetchAdminReports", reportApi.getByAdminUser(userId), undefined, reports => ({
            ownedReportIds: reports.owned.map(r => r.report.id),
            sharedReportIds: reports.shared.map(r => r.report.id),
          }))
          .catch(e => {
            alertStore.setFailedProcess("fetch reports", e);
          })
      : Promise.resolve({ shared: [], owned: [] }),
  );

  useMountEffect(() => {
    logger.mount("AssessmentReportHome");
    fetchAllReports();
  });

  const filterByReviewerName = (reportReviewerId?: string) => {
    if (!reportReviewerId) return false;
    const matchingReviewers = allReportReviewers(config)?.filter(reviewer =>
      reviewer.name.toLowerCase().includes(searchString.toLowerCase()),
    );

    return matchingReviewers?.some(reviewer => reviewer.userId === reportReviewerId);
  };

  const filterReportBySearchString = (r: HydratedAssessmentReport) => {
    return (
      !searchString ||
      r.user?.fullName?.toLowerCase().includes(searchString.toLowerCase()) ||
      r.report.clientUserId.includes(searchString.toLowerCase()) ||
      filterByReviewerName(r.report?.reviewerUserId)
    );
  };

  const filterReportsByStatus = (status: ReportStatus) => {
    const ownedReports = allReports?.owned?.filter(hydratedReport => hydratedReport.report?.status === status) || [];
    const sharedReports = allReports?.shared?.filter(hydratedReport => hydratedReport.report?.status === status) || [];

    return [...ownedReports, ...sharedReports]?.filter(r => filterReportBySearchString(r));
  };

  if (!userId) return <NotPermissable />;

  let content: ReactNode;
  const isLoading = !allReports || isCreating;
  if (!isLoading) {
    content = (
      <>
        <SearchTextInput value={searchString} setValue={setSearchString} placeholder="Search reports..." />

        <AssessmentReportList
          listType={AssessmentListType.ScoreEntry}
          reports={filterReportsByStatus(ReportStatus.ScoreEntry)}
        />

        <AssessmentReportList
          listType={AssessmentListType.Interpretation}
          reports={filterReportsByStatus(ReportStatus.Interpretation)}
        />

        <AssessmentReportList
          listType={AssessmentListType.Review}
          reports={filterReportsByStatus(ReportStatus.Review)}
        />

        <AssessmentReportList
          listType={AssessmentListType.Complete}
          reports={filterReportsByStatus(ReportStatus.Complete)}
        />
      </>
    );
  }

  return (
    <CommonScreenLayout
      headerText="Assessment Reports"
      headerControls={
        !isLoading &&
        !disableOldReportCreate && (
          <Button
            text="New Report"
            icon={<DocumentPlusIcon />}
            onClick={() => redirect("/assessment/report/new", "navigate to new report")}
          />
        )
      }
    >
      <div className="w-full h-full flex flex-col gap-8 overflow-y-auto">
        {content}
        {showCreateModal && (
          <CreateReportModal
            inProgressClientIds={mapExists(allReports?.owned, r =>
              r.report.status !== ReportStatus.Complete ? r.report.clientUserId : undefined,
            )}
            currUserId={userId}
            setIsLoading={setIsCreating}
            onClose={() => setShowCreateModal(false)}
          />
        )}
      </div>
      {isLoading && <LoadingModal />}
    </CommonScreenLayout>
  );
};

export default observer(AssessmentReportHome);
