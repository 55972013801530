const STAGING_PROJECT = "parallel-staging-352118";
const PROD_PROJECT = "parallel-prod";

const FIREBASE_SITES = [
  { host: "pathway-s", project: STAGING_PROJECT, nexusService: "pathway" },
  { host: "report-writer-s", project: STAGING_PROJECT, nexusService: "org" },
  { host: "telehealth-s", project: STAGING_PROJECT, nexusService: "telehealth" },
  { host: "pathway-p", project: PROD_PROJECT, nexusService: "pathway" },
  { host: "report-writer-p", project: PROD_PROJECT, nexusService: "org" },
  { host: "telehealth-p", project: PROD_PROJECT, nexusService: "telehealth" },
];

const NEXUS_VERSION_ALT_HOST_MATCHER = new RegExp(
  `(${FIREBASE_SITES.map(s => s.host).join("|")})--nexus-(\\w+)-\\w+\\.web\\.app`,
);

const buildNexusVersionUrl = ({
  nexusVersion,
  nexusService,
  gcpProject,
}: {
  nexusVersion: string;
  nexusService: string;
  gcpProject: string;
}) => `https://${nexusVersion}-dot-${nexusService}-dot-${gcpProject}.uc.r.appspot.com`;

/**
 * if this client is hosted at an firebase alternate domain containing a nexus server version,
 *  derive + return the nexus server version url
 */
export const resolveClientConfigOverrides = () => {
  if (!global.window) return undefined;

  const nexusVersionAltHostMatch = window.location.host.match(NEXUS_VERSION_ALT_HOST_MATCHER);
  if (!nexusVersionAltHostMatch) return;

  const hostMatch = nexusVersionAltHostMatch[1];
  const firebaseSite = FIREBASE_SITES.find(s => s.host === hostMatch);
  if (!firebaseSite) return;

  const nexusServerUrl = buildNexusVersionUrl({
    nexusVersion: nexusVersionAltHostMatch[2],
    nexusService: firebaseSite.nexusService,
    gcpProject: firebaseSite.project,
  });

  const reportNexusVersion = process.env.VITE_REPORT_STAGED_GAE_VERSION;
  if (!reportNexusVersion) return { nexusServerUrl };

  const nexusReportServerUrl = buildNexusVersionUrl({
    nexusVersion: reportNexusVersion,
    nexusService: firebaseSite.nexusService,
    gcpProject: firebaseSite.project,
  });
  return { nexusServerUrl, nexusReportServerUrl };
};
