import { isFunction } from "lodash";
import { resolveClientConfigOverrides } from "./client.config";
import { EnvConfig, getEnvConfig, isProd } from "./env.config";
import { NoOverrideStaticConfig, StaticConfig, getStaticConfig } from "./static.config";

type CustomEnvConfig<A extends object, B extends object> = {
  preprod: A;
  prod: A;
  global?: B;
  local?: Partial<A & StaticConfig> | ((c: StaticConfig) => Partial<A & StaticConfig>);
};

export type ServiceConfig<A extends object, B extends object> = StaticConfig &
  NoOverrideStaticConfig &
  EnvConfig &
  A &
  B;

export const initServiceConfig = <A extends object, B extends object>(
  rawEnv: any,
  custom: CustomEnvConfig<A, B>,
): ServiceConfig<A, B> => {
  const envConfig = getEnvConfig(rawEnv);
  const staticConfig = getStaticConfig(envConfig.parallelEnv);
  const customConfig = isProd(envConfig.parallelEnv) ? custom.prod : custom.preprod;

  let localOverrides: Partial<A & StaticConfig> = {};
  if (envConfig.parallelEnv === "local" && custom.local) {
    localOverrides = isFunction(custom.local) ? custom.local(staticConfig) : custom.local;
  }

  return {
    ...staticConfig,
    ...customConfig,
    ...localOverrides,
    ...envConfig,
    ...(custom.global || ({} as B)),
    ...resolveClientConfigOverrides(),
  };
};
