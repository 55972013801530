/**
 * for every member of `things`, wait for the result of `process`
 *  and pass the result through to process with the next member of `things` (start process w/ `initial`)
 *
 * if `catchError` is supplied, then all errors performing `process` will be caught and the supplied callbacks will run
 *  if not, the first `process` error will result in the remaing operations not running + the failed promise being returned
 */
type CatchError<A> = {
  onFailure?: (a: A, e: any) => void;
  onSuccess?: (a: A) => void;
};
export const reduceAsyncSequence = <A, B>(
  things: A[],
  process: (a: A, b: B, i: number) => Promise<B>,
  initial: B,
  { onFailure, onSuccess }: CatchError<A> = {},
): Promise<B> => {
  const initialPromise: Promise<B> = Promise.resolve(initial);
  return things.reduce(
    (currPromise, nextThing, i) =>
      currPromise.then(promised =>
        process(nextThing, promised, i)
          .then(result => {
            onSuccess && onSuccess(nextThing);
            return result;
          })
          .catch(e => {
            if (onFailure) {
              onFailure(nextThing, e);
              return promised;
            } else {
              throw e;
            }
          }),
      ),
    initialPromise,
  );
};

export const processSequence = <A, B>(things: A[], process: (a: A, i: number) => Promise<B>): Promise<B[]> =>
  reduceAsyncSequence(things, (a, results, i) => process(a, i).then(b => [...results, b]), [] as B[]);

export class TimeoutError extends Error {
  constructor(fnName: string) {
    super(`${fnName} timed out`);
  }
}

export const processWithTimeout = <A>(
  fnName: string,
  fn: () => Promise<A>,
  timeoutMs: number | undefined,
): Promise<A> =>
  !timeoutMs
    ? fn()
    : Promise.race([
        fn(),
        new Promise<A>((_, reject) => setTimeout(() => reject(new TimeoutError(fnName)), timeoutMs)),
      ]);

export const delay = (delayMs: number) => new Promise(resolve => setTimeout(resolve, delayMs));

export const findAsync = <A, B>(things: A[], process: (a: A) => Promise<B | undefined>): Promise<B | undefined> =>
  reduceAsyncSequence(
    things,
    (a, maybeResult) => (maybeResult ? Promise.resolve(maybeResult) : process(a)),
    undefined as B | undefined,
  );
